'use strict';

module.exports = function mode(key) {
  var values = [];
  var highestCount = 1;

  if (!this.items.length) {
    return null;
  }

  this.items.forEach(function (item) {
    var tempValues = values.filter(function (value) {
      if (key !== undefined) {
        return value.key === item[key];
      }

      return value.key === item;
    });

    if (!tempValues.length) {
      if (key !== undefined) {
        values.push({
          key: item[key],
          count: 1
        });
      } else {
        values.push({
          key: item,
          count: 1
        });
      }
    } else {
      tempValues[0].count += 1;
      var count = tempValues[0].count;

      if (count > highestCount) {
        highestCount = count;
      }
    }
  });
  return values.filter(function (value) {
    return value.count === highestCount;
  }).map(function (value) {
    return value.key;
  });
};