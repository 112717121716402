import Vue from 'vue'
import * as moment from 'moment'
// eslint-disable-next-line import/no-named-as-default
import FunctionalCalendar from 'vue-functional-calendar'

export default ({ i18n, $moment }) => {
  const fixStartWeek = (days) => {
    days.push(days.shift())

    return days
  }

  $moment.locale(i18n.locale)

  Vue.use(FunctionalCalendar, {
    sundayStart: true,
    isSeparately: false,
    transition: false,
    isDateRange: true,
    isMultiple: true,
    calendarsCount: 13,
    dateFormat: 'dd-mm-yyyy',
    minSelDays: 2,
    maxSelDays: 31,
    limits: {
      min: moment().locale('en').format('D-M-YYYY'),
      max: moment().locale('en').add(1, 'years').format('D-M-YYYY')
    },
    dayNames: fixStartWeek($moment.weekdaysShort()),
    monthNames: $moment.months(),
    shortMonthNames: $moment.monthsShort()
  })
}
