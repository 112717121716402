import { render, staticRenderFns } from "./placeInput.vue?vue&type=template&id=3e8cfa58&"
import script from "./placeInputImpl.js?vue&type=script&lang=js&"
export * from "./placeInputImpl.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/vercel/path0/components/common/Input.vue').default,Label: require('/vercel/path0/components/common/Label.vue').default})
