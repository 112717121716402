import { VuexPersistence } from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    reducer: state => ({
      country: state.country,
      currency: state.currency,
      favorites: state.favorites,
      fid: state.fid,
      priceDisplay: state.priceDisplay,
      visited: state.visited,
      closed: state.closed,
      referral: state.referral,
      searches: state.searches,
      unit: state.unit
    })
  }).plugin(store)
}
