
export default {
  name: 'WeekNumbers',
  props: {
    number: {
      tyoe: Number,
      required: true
    },
    borderColor: {
      type: String,
      default: ''
    }
  }
}
