
export default {
  props: {
    origin: {
      type: String,
      default: 'origin-top'
    }
  },
  data: () => ({ visible: false }),
  watch: {
    $route () {
      this.visible = false
    }
  },
  mounted () {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.close()
      }
    })
  },
  methods: {
    toggle () {
      this.visible = !this.visible
    },
    close () {
      this.visible = false
    }
  }
}
