
export default {
  name: 'NuxtError',

  props: {
    error: {
      type: Object,
      default: null
    }
  },

  head () {
    return {
      title: this.message
    }
  },

  computed: {
    statusCode () {
      return (this.error && this.error.statusCode) || 500
    },
    message () {
      return this.error.message || '<%= messages.client_error %>'
    }
  },

  mounted () {
    // if (this.statusCode === 404) {
    //   this.$router.push({
    //     path: this.localePath('index')
    //   })
    // }
  }
}
