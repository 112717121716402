
import Logo from '@/assets/images/logo.svg?inline'
import Times from '@/assets/icons/fontawesome/light/times.svg?inline'
import ChevronLeft from '@/assets/icons/fontawesome/light/chevron-left.svg?inline'
import ChevronRight from '@/assets/icons/fontawesome/light/chevron-right.svg?inline'

export default {
  components: {
    Logo,
    Times,
    ChevronLeft,
    ChevronRight
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    swipe: {
      type: Boolean,
      default: true
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    modalClass: {
      type: String,
      default: 'sm:max-w-xl'
    }
  },
  watch: {
    show (value) {
      if (value) {
        return document.querySelector('body').classList.add('overflow-hidden')
      }

      document.querySelector('body').classList.remove('overflow-hidden')
    }
  },
  mounted () {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.close()
      }
    })
  },
  methods: {
    swipeHandler (direction) {
      if (!this.swipe || screen.width > 639) {
        return
      }

      if (direction === 'left' && this.dir === 'ltr') {
        this.close()
      }

      if (direction === 'right' && this.dir === 'rtl') {
        this.close()
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
