
export default {
  name: 'MonthYearPicker',
  props: {
    calendarKey: {
      type: Number,
      default: 0
    },
    changeYearStep: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      delta: 0
    }
  },
  watch: {
    delta(newDelta) {
      if (newDelta < -new Date().getFullYear()) this.delta = 0
    }
  },
  methods: {
    changePicker(to) {
      if (this.$parent.showMonthPicker) {
        if (to === 'right') this.$parent.NextYear(this.calendarKey)
        else this.$parent.PreYear(this.calendarKey)
        return
      }

      if (to === 'right') this.delta += this.changeYearStep
      else this.delta -= this.changeYearStep
    }
  }
}
