'use strict';

module.exports = function SymbolIterator() {
  var _this = this;

  var index = -1;
  return {
    next: function next() {
      index += 1;
      return {
        value: _this.items[index],
        done: index >= _this.items.length
      };
    }
  };
};