export default ({ $axios, app, i18n, $config: { apiClientToken } }) => {
  $axios.onRequest((config) => {
    config.params = config.params || {}
    config.params.locale = i18n.locale

    if (!app.$auth.loggedIn &&
      config.url !== app.$auth.strategies.cookie.options.endpoints.user.url &&
      config.url !== app.$auth.strategies.cookie.options.endpoints.login.url
    ) {
      config.headers.common.Authorization = `Bearer ${apiClientToken}`
    }
  })

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)

    if (code === 400) {
      // redirect('/400')
    }
  })
}
