export default function ({ $auth, app, i18n, redirect }) {
  if (i18n.locale !== 'en') {
    const redirect = $auth.$storage.options.redirect

    for (const key in redirect) {
      redirect[key] = '/' + i18n.locale + redirect[key]
    }

    $auth.$storage.options.redirect = redirect
  }

  $auth.onError(async (error, name, endpoint) => {
    const code = parseInt(error.response && error.response.status)

    if (code === 401) {
      await $auth.logout()

      redirect(app.localePath('/login'))
    }
  })
}
