import { render, staticRenderFns } from "./ColorModeToggle.vue?vue&type=template&id=188e3358&scoped=true&"
import script from "./ColorModeToggle.vue?vue&type=script&lang=js&"
export * from "./ColorModeToggle.vue?vue&type=script&lang=js&"
import style0 from "./ColorModeToggle.vue?vue&type=style&index=0&id=188e3358&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188e3358",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/components/common/Button.vue').default})
