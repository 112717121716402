
import FacebookF from '@/assets/icons/fontawesome/brands/facebook-f.svg?inline'
import FacebookMessenger from '@/assets/icons/fontawesome/brands/facebook-messenger.svg?inline'
import Instagram from '@/assets/icons/fontawesome/brands/instagram.svg?inline'
import LinkedinIn from '@/assets/icons/fontawesome/brands/linkedin-in.svg?inline'
import Twitter from '@/assets/icons/fontawesome/brands/twitter.svg?inline'
import Whatsapp from '@/assets/icons/fontawesome/brands/whatsapp.svg?inline'
import Youtube from '@/assets/icons/fontawesome/brands/youtube.svg?inline'

export default {
  components: {
    FacebookF,
    FacebookMessenger,
    Instagram,
    LinkedinIn,
    Twitter,
    Whatsapp,
    Youtube
  }
}
