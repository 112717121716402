export const Customer = () => import('../../components/cards/Customer.vue' /* webpackChunkName: "components/customer" */).then(c => wrapFunctional(c.default || c))
export const Duration = () => import('../../components/cards/Duration.vue' /* webpackChunkName: "components/duration" */).then(c => wrapFunctional(c.default || c))
export const Hotel = () => import('../../components/cards/Hotel.vue' /* webpackChunkName: "components/hotel" */).then(c => wrapFunctional(c.default || c))
export const HotelResult = () => import('../../components/cards/HotelResult.vue' /* webpackChunkName: "components/hotel-result" */).then(c => wrapFunctional(c.default || c))
export const Incomes = () => import('../../components/cards/Incomes.vue' /* webpackChunkName: "components/incomes" */).then(c => wrapFunctional(c.default || c))
export const Items = () => import('../../components/cards/Items.vue' /* webpackChunkName: "components/items" */).then(c => wrapFunctional(c.default || c))
export const OriginalIncomes = () => import('../../components/cards/OriginalIncomes.vue' /* webpackChunkName: "components/original-incomes" */).then(c => wrapFunctional(c.default || c))
export const OriginalPayments = () => import('../../components/cards/OriginalPayments.vue' /* webpackChunkName: "components/original-payments" */).then(c => wrapFunctional(c.default || c))
export const OriginalPrice = () => import('../../components/cards/OriginalPrice.vue' /* webpackChunkName: "components/original-price" */).then(c => wrapFunctional(c.default || c))
export const Payments = () => import('../../components/cards/Payments.vue' /* webpackChunkName: "components/payments" */).then(c => wrapFunctional(c.default || c))
export const Price = () => import('../../components/cards/Price.vue' /* webpackChunkName: "components/price" */).then(c => wrapFunctional(c.default || c))
export const Secure = () => import('../../components/cards/Secure.vue' /* webpackChunkName: "components/secure" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumb = () => import('../../components/common/AppBreadcrumb.vue' /* webpackChunkName: "components/app-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const AppHero = () => import('../../components/common/AppHero.vue' /* webpackChunkName: "components/app-hero" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/common/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/common/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/common/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../components/common/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const Info = () => import('../../components/common/Info.vue' /* webpackChunkName: "components/info" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../components/common/Input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const InputGroup = () => import('../../components/common/InputGroup.vue' /* webpackChunkName: "components/input-group" */).then(c => wrapFunctional(c.default || c))
export const InputLabel = () => import('../../components/common/InputLabel.vue' /* webpackChunkName: "components/input-label" */).then(c => wrapFunctional(c.default || c))
export const Label = () => import('../../components/common/Label.vue' /* webpackChunkName: "components/label" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../components/common/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const ListDivider = () => import('../../components/common/ListDivider.vue' /* webpackChunkName: "components/list-divider" */).then(c => wrapFunctional(c.default || c))
export const ListGroup = () => import('../../components/common/ListGroup.vue' /* webpackChunkName: "components/list-group" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/common/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModalScreen = () => import('../../components/common/ModalScreen.vue' /* webpackChunkName: "components/modal-screen" */).then(c => wrapFunctional(c.default || c))
export const Network = () => import('../../components/common/Network.vue' /* webpackChunkName: "components/network" */).then(c => wrapFunctional(c.default || c))
export const Popup = () => import('../../components/common/Popup.vue' /* webpackChunkName: "components/popup" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../components/common/Select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const Slash = () => import('../../components/common/Slash.vue' /* webpackChunkName: "components/slash" */).then(c => wrapFunctional(c.default || c))
export const CardLoader = () => import('../../components/loaders/CardLoader.vue' /* webpackChunkName: "components/card-loader" */).then(c => wrapFunctional(c.default || c))
export const HotelLoader = () => import('../../components/loaders/HotelLoader.vue' /* webpackChunkName: "components/hotel-loader" */).then(c => wrapFunctional(c.default || c))
export const HotelResultLoader = () => import('../../components/loaders/HotelResultLoader.vue' /* webpackChunkName: "components/hotel-result-loader" */).then(c => wrapFunctional(c.default || c))
export const BookingRating = () => import('../../components/partials/BookingRating.vue' /* webpackChunkName: "components/booking-rating" */).then(c => wrapFunctional(c.default || c))
export const Calendar = () => import('../../components/partials/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const CancellationButton = () => import('../../components/partials/CancellationButton.vue' /* webpackChunkName: "components/cancellation-button" */).then(c => wrapFunctional(c.default || c))
export const CancellationPolicy = () => import('../../components/partials/CancellationPolicy.vue' /* webpackChunkName: "components/cancellation-policy" */).then(c => wrapFunctional(c.default || c))
export const ColorModeToggle = () => import('../../components/partials/ColorModeToggle.vue' /* webpackChunkName: "components/color-mode-toggle" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../components/partials/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const Countries = () => import('../../components/partials/Countries.vue' /* webpackChunkName: "components/countries" */).then(c => wrapFunctional(c.default || c))
export const Currencies = () => import('../../components/partials/Currencies.vue' /* webpackChunkName: "components/currencies" */).then(c => wrapFunctional(c.default || c))
export const Currency = () => import('../../components/partials/Currency.vue' /* webpackChunkName: "components/currency" */).then(c => wrapFunctional(c.default || c))
export const Dates = () => import('../../components/partials/Dates.vue' /* webpackChunkName: "components/dates" */).then(c => wrapFunctional(c.default || c))
export const Favorite = () => import('../../components/partials/Favorite.vue' /* webpackChunkName: "components/favorite" */).then(c => wrapFunctional(c.default || c))
export const FilterHotels = () => import('../../components/partials/FilterHotels.vue' /* webpackChunkName: "components/filter-hotels" */).then(c => wrapFunctional(c.default || c))
export const FilterResevations = () => import('../../components/partials/FilterResevations.vue' /* webpackChunkName: "components/filter-resevations" */).then(c => wrapFunctional(c.default || c))
export const FilterRooms = () => import('../../components/partials/FilterRooms.vue' /* webpackChunkName: "components/filter-rooms" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/partials/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapMarker = () => import('../../components/partials/GoogleMapMarker.vue' /* webpackChunkName: "components/google-map-marker" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapNearBy = () => import('../../components/partials/GoogleMapNearBy.vue' /* webpackChunkName: "components/google-map-near-by" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapResults = () => import('../../components/partials/GoogleMapResults.vue' /* webpackChunkName: "components/google-map-results" */).then(c => wrapFunctional(c.default || c))
export const Guests = () => import('../../components/partials/Guests.vue' /* webpackChunkName: "components/guests" */).then(c => wrapFunctional(c.default || c))
export const HotelFacilities = () => import('../../components/partials/HotelFacilities.vue' /* webpackChunkName: "components/hotel-facilities" */).then(c => wrapFunctional(c.default || c))
export const HotelFacility = () => import('../../components/partials/HotelFacility.vue' /* webpackChunkName: "components/hotel-facility" */).then(c => wrapFunctional(c.default || c))
export const HotelGallery = () => import('../../components/partials/HotelGallery.vue' /* webpackChunkName: "components/hotel-gallery" */).then(c => wrapFunctional(c.default || c))
export const Locales = () => import('../../components/partials/Locales.vue' /* webpackChunkName: "components/locales" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../components/partials/Login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const MapboxMapMarker = () => import('../../components/partials/MapboxMapMarker.vue' /* webpackChunkName: "components/mapbox-map-marker" */).then(c => wrapFunctional(c.default || c))
export const MapboxMapNearBy = () => import('../../components/partials/MapboxMapNearBy.vue' /* webpackChunkName: "components/mapbox-map-near-by" */).then(c => wrapFunctional(c.default || c))
export const MapboxMapResults = () => import('../../components/partials/MapboxMapResults.vue' /* webpackChunkName: "components/mapbox-map-results" */).then(c => wrapFunctional(c.default || c))
export const MultipleRooms = () => import('../../components/partials/MultipleRooms.vue' /* webpackChunkName: "components/multiple-rooms" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/partials/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NearBy = () => import('../../components/partials/NearBy.vue' /* webpackChunkName: "components/near-by" */).then(c => wrapFunctional(c.default || c))
export const NearByCard = () => import('../../components/partials/NearByCard.vue' /* webpackChunkName: "components/near-by-card" */).then(c => wrapFunctional(c.default || c))
export const NearByMobile = () => import('../../components/partials/NearByMobile.vue' /* webpackChunkName: "components/near-by-mobile" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/partials/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const Promotion = () => import('../../components/partials/Promotion.vue' /* webpackChunkName: "components/promotion" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/partials/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const RoomGallery = () => import('../../components/partials/RoomGallery.vue' /* webpackChunkName: "components/room-gallery" */).then(c => wrapFunctional(c.default || c))
export const Rooms = () => import('../../components/partials/Rooms.vue' /* webpackChunkName: "components/rooms" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/partials/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SearchSummary = () => import('../../components/partials/SearchSummary.vue' /* webpackChunkName: "components/search-summary" */).then(c => wrapFunctional(c.default || c))
export const SessionError = () => import('../../components/partials/SessionError.vue' /* webpackChunkName: "components/session-error" */).then(c => wrapFunctional(c.default || c))
export const Socials = () => import('../../components/partials/Socials.vue' /* webpackChunkName: "components/socials" */).then(c => wrapFunctional(c.default || c))
export const SortHotels = () => import('../../components/partials/SortHotels.vue' /* webpackChunkName: "components/sort-hotels" */).then(c => wrapFunctional(c.default || c))
export const Stars = () => import('../../components/partials/Stars.vue' /* webpackChunkName: "components/stars" */).then(c => wrapFunctional(c.default || c))
export const Timer = () => import('../../components/partials/Timer.vue' /* webpackChunkName: "components/timer" */).then(c => wrapFunctional(c.default || c))
export const Translate = () => import('../../components/partials/Translate.vue' /* webpackChunkName: "components/translate" */).then(c => wrapFunctional(c.default || c))
export const TrustYou = () => import('../../components/partials/TrustYou.vue' /* webpackChunkName: "components/trust-you" */).then(c => wrapFunctional(c.default || c))
export const TrustYouCategory = () => import('../../components/partials/TrustYouCategory.vue' /* webpackChunkName: "components/trust-you-category" */).then(c => wrapFunctional(c.default || c))
export const TrustYouRatingProgress = () => import('../../components/partials/TrustYouRatingProgress.vue' /* webpackChunkName: "components/trust-you-rating-progress" */).then(c => wrapFunctional(c.default || c))
export const SearchBox = () => import('../../components/SearchBox.vue' /* webpackChunkName: "components/search-box" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
