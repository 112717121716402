
import collect from 'collect.js'
import Times from '@/assets/icons/fontawesome/light/times.svg?inline'

export default {
  components: {
    Times
  },
  data () {
    return {
      errors: {},
      name: null,
      sent: false,
      country_prefix: '1',
      phone: null,
      email: null,
      modal: false,
      sending: false
    }
  },
  computed: {
    prefixes () {
      return collect(this.countries).sortBy('dial_code').all()
    },
    currentCountry () {
      const current = this.countries.find(c => c.code === this.country)

      if (current) {
        return current
      }

      return this.countries.find(c => c.code === 'US')
    }
  },
  mounted () {
    this.country_prefix = this.currentCountry.dial_code

    this.popup()
  },
  methods: {
    popup () {
      const timer = 120
      const period = 7
      const visited = this.$moment().diff(this.$moment(this.visited), 'seconds')
      const closed = this.$moment().diff(this.$moment(this.closed), 'days')

      if (this.closed === null) {
        if (visited >= timer) {
          setTimeout(() => {
            this.modal = true
          }, 10000)
        } else {
          setTimeout(() => {
            this.modal = true
          }, (timer - visited) * 1000)
        }
      } else if (closed > period) {
        this.setVisited(this.$moment())
      }
    },
    setPrefix (prefix) {
      this.country_prefix = prefix.dial_code
      this.showPrefixes = false
    },
    async send () {
      this.sending = true

      await this.$axios.$post('/v3/services/proxy', {
        to: 'https://app.travelor.com/lead',
        bot: this.$i18n.locale === 'he' ? 1 : 0,
        token: 'no',
        name: this.name,
        fullname: this.name,
        email: this.email,
        addToGroups: this.$i18n.locale === 'he' ? '217379' : '243420',
        lang: this.$i18n.locale,
        title: 'Travelor',
        url: this.$config.baseURL + this.$route.fullPath,
        calling_code: this.country_prefix,
        phone: this.phone,
        ref: this.$route.query.fid || 3,
        userid: this.$route.query.fid || 3
      }).then((response) => {
        this.sent = true
        this.email = null
        this.email = null
        this.phone = null

        // this.$toast.success(this.$i18n.t('Successfully sent.'))

        setTimeout(() => {
          this.close()
        }, 2000)
      }).catch((error) => {
        if (error.response && typeof error.response.data === 'object') {
          this.errors = error.response.data.errors
        } else {
          this.sent = true
          this.email = null
          this.email = null
          this.phone = null

          this.$toast.error('Something went wrong. Please try again.')

          setTimeout(() => {
            this.close()
          }, 2000)
        }
      })

      this.sending = false
    },
    close () {
      this.modal = false
      this.setClosed(this.$moment())
    }
  }
}
