
import Logo from '@/assets/images/logo.svg?inline'
import Bars from '@/assets/icons/fontawesome/light/bars.svg?inline'
import Times from '@/assets/icons/fontawesome/light/times.svg?inline'
import ChevronLeft from '@/assets/icons/fontawesome/light/chevron-left.svg?inline'
import ChevronRight from '@/assets/icons/fontawesome/light/chevron-right.svg?inline'

export default {
  components: {
    Logo,
    Bars,
    Times,
    ChevronLeft,
    ChevronRight
  },
  props: {
    view: {
      type: String,
      default: 'default'
    },
    return: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      mobileMenuOpen: false
    }
  },
  computed: {
    links () {
      if (this.country === 'IL' || this.$i18n.locale === 'he') {
        return [
          { key: 'Hotels', to: 'hotels' }
          // { key: 'Hotels in Israel', to: 'hotels-israel' }
        ]
      }

      return [
        { key: 'Hotels', to: 'hotels' }
      ]
    }
  },
  watch: {
    $route () {
      this.mobileMenuOpen = false
    }
  },
  methods: {
    back () {
      if (this.return) {
        this.$router.back()
      } else {
        this.$emit('close')
      }
    }
  }
}
