import moment from 'moment'

import 'moment/locale/he'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/ar'
import 'moment/locale/ru'
import 'moment/locale/id'
import 'moment/locale/ms'
import 'moment/locale/ca'
import 'moment/locale/da'
import 'moment/locale/de'
import 'moment/locale/et'
import 'moment/locale/hr'
import 'moment/locale/it'
import 'moment/locale/lt'
import 'moment/locale/hu'
import 'moment/locale/nl'
import 'moment/locale/pl'
import 'moment/locale/pt'
import 'moment/locale/pt-br'
import 'moment/locale/ro'
import 'moment/locale/sk'
import 'moment/locale/sl'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import 'moment/locale/tr'
import 'moment/locale/is'
import 'moment/locale/cs'
import 'moment/locale/el'
import 'moment/locale/sr'
import 'moment/locale/bg'
import 'moment/locale/tl-ph'
import 'moment/locale/uk'
import 'moment/locale/th'
import 'moment/locale/ja'
import 'moment/locale/zh-cn'
import 'moment/locale/zh-tw'
import 'moment/locale/ko'

moment.locale('en')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
