import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=32e94e4b&scoped=true&"
import script from "./TheFooter.vue?vue&type=script&lang=js&"
export * from "./TheFooter.vue?vue&type=script&lang=js&"
import style0 from "./TheFooter.vue?vue&type=style&index=0&id=32e94e4b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e94e4b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Countries: require('/vercel/path0/components/partials/Countries.vue').default,Socials: require('/vercel/path0/components/partials/Socials.vue').default})
