
import Check from '@/assets/icons/fontawesome/light/check.svg?inline'

export default {
  components: {
    Check
  },

  props: {
    title: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      modal: false
    }
  },

  methods: {
    change (country) {
      this.setCountry(country)

      this.modal = false
    }
  }
}
