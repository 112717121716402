import { render, staticRenderFns } from "./Promotion.vue?vue&type=template&id=451e6f4b&"
import script from "./Promotion.vue?vue&type=script&lang=js&"
export * from "./Promotion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/vercel/path0/components/common/Input.vue').default,Label: require('/vercel/path0/components/common/Label.vue').default,InputGroup: require('/vercel/path0/components/common/InputGroup.vue').default,Button: require('/vercel/path0/components/common/Button.vue').default,Info: require('/vercel/path0/components/common/Info.vue').default,Modal: require('/vercel/path0/components/common/Modal.vue').default})
