import { render, staticRenderFns } from "./Input.vue?vue&type=template&id=2ef61cc6&"
import script from "./Input.vue?vue&type=script&lang=js&"
export * from "./Input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/vercel/path0/components/common/Input.vue').default})
